<template>
  <div class="receivable-list">
    <section class="content">
      <a-row style="display: flex">
        <a-col><breadcrumb></breadcrumb></a-col>
      </a-row>
      <section style="margin-bottom: 1%">
        <a-button type="primary" @click="createHandle">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t('新增应收单') }}
        </a-button>
      </section>
      <a-row :gutter="16">
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.orderSn"
            :placeholder="$t('请输入应收单号')"
          />
        </a-col>
        <a-col :span="6">
          <date-range-picker
            id="effectiveTime"
            :needhms="false"
            @get_date="chooseOrderStart"
            :placeholder="$t(`form.OrderTime`)"
            :start-time="formData.orderTimeStart"
            :end-time="formData.orderTimeEnd"
          ></date-range-picker>
        </a-col>
        <a-col :span="6">
          <CommonQuerySelect
            api="getCommonList"
            :placeholder="$t('客户')"
            :code.sync="formData.customerCode"
            :params="{
              businessUnitCode: formData.businessUnitCode,
              tableName: 'customer',
            }"
          />
        </a-col>
        <a-col :span="6">
          <CommonSelect
            :list="receivable_order_type"
            :code.sync="formData.orderType"
            :placeholder="$t('单据类型')"
          ></CommonSelect>
        </a-col>
      </a-row>
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <a-button @click="searchHandle(1)" class="btnBgc">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`form.Search`) }}
          </a-button>
        </a-col>
      </a-row>

      <CommonTable
        ref="CommonTableRef"
        :statusList="receivable_order_status"
        :selectedRowKeys.sync="selectedRowKeys"
        size="small"
        :scroll="{ x: 1500 }"
        :columns="tableColumns"
        :data-source="tableData"
        rowKey="id"
        :total="total"
        :loading="tableLoading"
        @search="queryOrderList"
      >
        <template v-slot:orderSn="{ record }">
          <a @click="viewDetail(record)">{{ record.orderSn }}</a>
        </template>
        <template v-slot:amountWithoutTax="{ record }">
          <PriceInput
            :showTxt="true"
            :currency="record.functionalCurrency"
            :value="record.amountWithoutTax"
          />
        </template>
        <template v-slot:taxAmount="{ record }">
          <PriceInput
            :showTxt="true"
            :currency="record.functionalCurrency"
            :value="record.taxAmount"
          />
        </template>
        <template v-slot:receivableAmount="{ record }">
          <PriceInput
            :showTxt="true"
            :currency="record.functionalCurrency"
            :value="record.receivableAmount"
          />
        </template>
        <template v-slot:invoicedAmount="{ record }">
          <PriceInput
            :showTxt="true"
            :currency="record.functionalCurrency"
            :value="record.invoicedAmount"
          />
        </template>
      </CommonTable>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import dateRangePicker from '@component/dateRangePicker'
import { convertKeysToCamelCase, spaceToComma } from '@/common'
import { subtract } from '@/common/number'

export default {
  name: 'receivableList',
  activated() {
    this.searchHandle()
  },
  components: {
    dateRangePicker,
  },
  data() {
    return {
      formData: {
        orderSn: undefined,
        orderTimeStart: undefined,
        orderTimeEnd: undefined,
        status: '',
        supplierName: undefined,
      },
      tableLoading: false,
      total: 0,
      selectedRowKeys: [],
      tableData: [],
    }
  },
  computed: {
    ...mapState(['receivable_order_status', 'receivable_order_type']),
    ...mapGetters(['receivableOrderStatusMapping', 'receivableOrderTypeMapping']),
    tableColumns() {
      return [
        {
          dataIndex: 'orderSn',
          title: this.$t('应收单号'),
          key: 'orderSn',
          width: 200,
          scopedSlots: { customRender: 'orderSn' },
        },
        {
          title: this.$t('单据类型'),
          dataIndex: 'orderType',
          customRender: (text) => this.receivableOrderTypeMapping[text],
        },
        {
          title: this.$t('业务日期'),
          dataIndex: 'bizDate',
          width: 200,
        },
        {
          title: this.$t('应收单状态'),
          dataIndex: 'status',
          customRender: (text) => this.receivableOrderStatusMapping[text],
        },
        {
          title: this.$t('客户'),
          dataIndex: 'customerName',
        },
        {
          title: this.$t('金额') + this.$t('未含税'),
          width: 150,
          dataIndex: 'amountWithoutTax',
          scopedSlots: { customRender: 'amountWithoutTax' },
        },
        {
          title: this.$t('税额'),
          width: 150,
          dataIndex: 'taxAmount',
          scopedSlots: { customRender: 'taxAmount' },
        },
        {
          title: this.$t('应收金额'),
          dataIndex: 'receivableAmount',
          width: 150,
          scopedSlots: { customRender: 'receivableAmount' },
        },
        {
          title: this.$t('开票金额'),
          dataIndex: 'invoicedAmount',
          width: 150,
          scopedSlots: { customRender: 'invoicedAmount' },
        },
        {
          title: this.$t('结算组织'),
          dataIndex: 'businessUnitName',
        },
      ]
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    subtract,
    searchHandle(pageNo) {
      this.selectedRowKeys = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    supplierIndexChange(val) {
      this.formData.supplierName = val.supplierName
    },
    createHandle() {
      this.$router.push({ name: 'receivableDetail' })
    },
    viewDetail(record) {
      this.addPane({
        view: 'receivableDetail/' + record.orderSn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.orderSn,
        },
        route: `/receivableManage/receivableDetail?id=${record.id}&sn=${record.orderSn}`,
      })
    },
    chooseOrderStart(date) {
      this.formData.orderTimeStart = date.start_time
      this.formData.orderTimeEnd = date.end_time
    },
    async queryOrderList(params) {
      let data = {
        ...this.formData,
        ...params,
      }
      let orderSn = spaceToComma(this.formData.orderSn)
      if (orderSn.includes(',')) {
        data.orderSnList = orderSn.split(',')
      } else if (orderSn) {
        data.orderSn = orderSn
      }
      this.tableLoading = true
      let res = await http({
        type: 'post',
        url: api.getReceivableList,
        data,
      })
      this.tableData = convertKeysToCamelCase(res.result.list || [])
      this.total = res.result.total
      this.tableLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';
.btnBgc {
  background-color: #0088ff;
  color: #ffffff;
}
</style>
